<template>
  <div>
    <div
      v-for="(item, index) in [
        {
          label0: '经纪人注册数量',
          value0: columnAmount(brokerStatisticInfo.registerCount, {
            fixed: 0,
          }),

          label1: '活跃度',
          value1: `${columnAmount(brokerStatisticInfo.activity * 100)}%`,

          label2: '活跃数',
          value2: `${columnAmount(brokerStatisticInfo.activelyRegisterCount, {
            fixed: 0,
          })}`,

          column0: {
            label: '昨日新增',
            value: columnAmount(brokerStatisticInfo.yesterdayCount, {
              fixed: 0,
            }),
          },
          column1: {
            label: '本周新增',
            value: columnAmount(brokerStatisticInfo.weeklyCount, {
              fixed: 0,
            }),
          },
          column2: {
            label: '本月新增',
            value: columnAmount(brokerStatisticInfo.monthlyCount, {
              fixed: 0,
            }),
          },
        },

        {
          label0: '合伙人注册数量',
          value0: columnAmount(leaderStatisticInfo.registerCount, {
            fixed: 0,
          }),

          column0: {
            label: '昨日新增',
            value: columnAmount(leaderStatisticInfo.yesterdayCount, {
              fixed: 0,
            }),
          },
          column1: {
            label: '本周新增',
            value: columnAmount(leaderStatisticInfo.weeklyCount, {
              fixed: 0,
            }),
          },
          column2: {
            label: '本月新增',
            value: columnAmount(leaderStatisticInfo.monthlyCount, {
              fixed: 0,
            }),
          },
        },

        //
      ]"
      :key="index"
      class="
        bg_col_fff
        bor_rad_8
        box_sha_0_0_8_8_black_80_per
        p_16
        m_0_16 m_t_16
      "
    >
      <div class="dis_flex">
        <div class="flex_1">
          <div class="font_16 font_bold">{{ item.label0 }}</div>
          <div class="font_24 font_bold m_t_8">{{ item.value0 }}</div>
        </div>

        <div v-if="item.label1" class="m_0_16 m_t_4">
          <div class="font_14 font_bold">{{ item.label1 }}</div>
          <div class="font_18 font_bold m_t_8">{{ item.value1 }}</div>
        </div>

        <div v-if="item.label2" class="m_0_16 m_t_4">
          <div class="font_14 font_bold">{{ item.label2 }}</div>
          <div class="font_18 font_bold m_t_8">{{ item.value2 }}</div>
        </div>

        <!--  -->
      </div>

      <div class="dis_flex m_t_8">
        <div class="flex_1 dis_flex font_12">
          <div>{{ item.column0.label }}</div>
          <div class="col_66BB6A font_bold m_l_8">{{ item.column0.value }}</div>
        </div>

        <div class="flex_1 dis_flex font_12">
          <div>{{ item.column1.label }}</div>
          <div class="col_66BB6A font_bold m_l_8">{{ item.column1.value }}</div>
        </div>

        <div class="flex_1 dis_flex font_12">
          <div>{{ item.column2.label }}</div>
          <div class="col_2396F5 font_bold m_l_8">{{ item.column2.value }}</div>
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>

<script>
import { columnAmount } from '@/utils/tools';

import { brokerDashboardAppRegisterStatistics } from '@/api/index';

export default {
  components: {},
  data() {
    return {
      brokerStatisticInfo: {},
      leaderStatisticInfo: {},

      //
    };
  },
  computed: {},
  mounted() {
    this.init();

    //
  },
  methods: {
    columnAmount,

    // 获取 合伙人注册统计
    async getLeaderStatisticInfo() {
      this.leaderStatisticInfo = await brokerDashboardAppRegisterStatistics({
        brokerType: 'LEADER',
      });
    },

    // 获取 经纪人注册统计
    async getBrokerStatisticInfo() {
      this.brokerStatisticInfo = await brokerDashboardAppRegisterStatistics({
        brokerType: 'BROKER',
      });
    },

    // 初始化
    async init() {
      await this.getBrokerStatisticInfo();
      await this.getLeaderStatisticInfo();

      //
    },

    //
  },
};
</script>
